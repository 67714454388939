<template>
  <div class="form__flex form__flex-start">
    <div :class="[!isSplit && 'form__left']">
      <div class="field_criterion">
        <VSelect
          name="delivery_condition_id"
          id="delivery_condition_id"
          v-model="formData.delivery_condition_id"
          :errors="v$.delivery_condition_id.$errors"
          :server-errors="serverErrors.value?.delivery_condition_id"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          :options="condition"
          :is-min-height="true"
          classes="select-border-bottom"
          class="input-long"
          placeholder="Условия поставки"
        />

        <VCheckbox
          v-if="!isBidder"
          :class-form="['form-checkbox small-checkbox']"
          id="is_criterion_delivery"
          name="is_criterion_delivery"
          class-label="label"
          :value="formData.is_criterion_delivery"
          v-model="formData.is_criterion_delivery"
        >
          <template #default="slotProps">
            <label :for="slotProps.for" :class="slotProps.class">
              Критерий
            </label>
          </template>
        </VCheckbox>
      </div>

      <VSelect
        name="delivery_address"
        id="delivery_address"
        v-model="formData.delivery_address"
        :errors="v$.delivery_address.$errors"
        :server-errors="serverErrors.value?.delivery_address"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder ? !isEdit : false"
        @search="searchOptions"
        :options="isSMR ? addressSMR : address"
        key-record="name"
        track-by="name"
        is-custom-option
        is-search
        loader
        send-entirely
        multiple
        classes="select-border-bottom mb-40"
        class="input-long"
        placeholder="Адрес поставки"
      />

      <VDatePicker
        v-if="isSMR && (isBidder ? isEdit : true)"
        name="construction_start_at"
        id="construction_start_at"
        v-model="formData.construction_start_at"
        :errors="isSMR ? v$.construction_start_at.$errors : ''"
        :server-errors="serverErrors.value?.construction_start_at"
        @blur="validateField"
        :max="isSMR ? formData.construction_finish_at : ''"
        :disabled="isBidder ? !isEdit : false"
        @input="validateField"
        class-form="mb-20"
        placeholder="Начало выполнения работ"
      />

      <VInput
        v-if="isSMR && (isBidder ? !isEdit : false)"
        name="construction_start_at"
        id="construction_start_at"
        v-model="formData.construction_start_at"
        :disabled="isBidder ? !isEdit : false"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        placeholder="Начало выполнения работ"
      />

      <VDatePicker
        v-if="isSMR && (isBidder ? isEdit : true)"
        name="construction_finish_at"
        id="construction_finish_at"
        v-model="formData.construction_finish_at"
        :errors="isSMR ? v$.construction_finish_at.$errors : ''"
        :server-errors="serverErrors.value?.construction_finish_at"
        @blur="validateField"
        :min="isSMR ? formData.construction_start_at : ''"
        :disabled="isBidder ? !isEdit : false"
        @input="validateField"
        class-form="mb-20"
        placeholder="Окончание выполнения работ"
      />

      <VInput
        v-if="isSMR && (isBidder ? !isEdit : false)"
        name="construction_finish_at"
        id="construction_finish_at"
        v-model="formData.construction_finish_at"
        :disabled="isBidder ? !isEdit : false"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        placeholder="Окончание выполнения работ"
      />
    </div>

    <div :class="[!isSplit && 'form__right']">
      <VInput
        name="delivery_frequency"
        id="delivery_frequency"
        v-model="formData.delivery_frequency"
        :errors="v$.delivery_frequency.$errors"
        :server-errors="serverErrors.value?.delivery_frequency"
        @blur="validateField"
        @input="validateField"
        :disabled="isBidder ? !isEdit : false"
        :class-input="['input input-long input-placeholder-black input-border-bottom']"
        placeholder="Частота поставки"
      />

      <VSelect
          v-if="isSMR"
          name="construction_special_condition_id"
          id="construction_special_condition_id"
          v-model="formData.construction_special_condition_id"
          :server-errors="serverErrors.value?.construction_special_condition_id"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          :options="specialCondition"
          classes="select-border-bottom"
          class="input-long"
          placeholder="Особые условия"
      />

      <VSelect
          v-if="isSMR"
          name="construction_criteria_execution_id"
          id="construction_criteria_execution_id"
          v-model="formData.construction_criteria_execution_id"
          :server-errors="serverErrors.value?.construction_criteria_execution_id"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          :options="criteriaExecution"
          classes="select-border-bottom"
          class="input-long"
          placeholder="Критерии выполнения работ"
      />

      <VSelect
          v-if="isSMR"
          name="construction_term_contract_id"
          id="construction_term_contract_id"
          v-model="formData.construction_term_contract_id"
          :server-errors="serverErrors.value?.construction_term_contract_id"
          @blur="validateField"
          @input="validateField"
          :disabled="isBidder ? !isEdit : false"
          :options="termContract"
          classes="select-border-bottom"
          class="input-long"
          placeholder="Условие заключения договора с Подрядчиком"
      />
    </div>

    <VCheckbox
      v-if="!isBidder"
      id="delivery_ability_edit"
      name="delivery_ability_edit"
      v-model="formData.delivery_ability_edit"
      :server-errors="serverErrors.value?.delivery_ability_edit"
      class-form="form-checkbox mb-40"
      :is-switch="true"
      class-label="bg-white"
    >
      <template #default="slotProps">
        <label :for="slotProps.for" :class="slotProps.class">
          Редактируемые поставщиком условия поставки
        </label>
      </template>
    </VCheckbox>
  </div>
</template>

<script setup>
import { defineExpose, defineProps, inject, reactive, onMounted, computed } from 'vue'

import VDatePicker from '@/components/ui/form/VDatePicker'
import VSelect from '@/components/ui/form/VSelect'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import VInput from '@/components/ui/form/VInput'
import { checkAddress } from '@/http/services/datadata/dataDataApi'
import { manualMemoization } from '@/utils/memoization/manualMemoization'
import { CONDITIONS_DELIVERY, SPECIAL_CONDITIONS_DELIVERY, CRITERIAL_EXECUTION, TERM_CONTRACT } from '@/utils/manual/manualList'

defineProps({
  isBidder: {
    type: Boolean,
    required: false
  },
  isEdit: {
    type: Boolean,
    required: false
  },
  isSplit: {
    type: Boolean,
    required: false
  }
})

const formData = inject('formData')
const v$ = inject('v$')
const serverErrors = inject('serverErrors')
const validateField = inject('validateField')

const addressSMR = [
    { id: -1, name: '"Цех прародительского стада №1 (ПРС-1)» с. Кизильское (Сибайское направление, в 4-х километрах от пос.Кизила в направлении г.Сибая), кадастровый номер: 74:11:0103001:448.' },
    { id: -2, name: '«Цех прародительского стада №2 (ПРС-2)» в 2-х километрах западнее п. Увальский, кадастровый номер: 74:11:0801003:492.' },
    { id: -3, name: '«Площадка буртования помета» вблизи п. Обручевский (направление в сторону п. Путь Октября), кадастровый номер: 74:11:1002001:353.' }
]
const address = reactive([])
const condition = reactive([])
const specialCondition = reactive([])
const criteriaExecution = reactive([])
const termContract = reactive([])

const isSMR = computed(() => {
    let isSMR = false
    formData.categories.forEach(item => {
        if (item.is_construction === true) isSMR = true
    })
    if (isSMR && (formData.procedure_type_id === 2 || formData.procedure_type_id === 3)) return true
    else return false
})

onMounted(async () => {
  condition.push(...await manualMemoization(true, CONDITIONS_DELIVERY))
  specialCondition.push(...await manualMemoization(true, SPECIAL_CONDITIONS_DELIVERY))
  criteriaExecution.push(...await manualMemoization(true, CRITERIAL_EXECUTION))
  termContract.push(...await manualMemoization(true, TERM_CONTRACT))
  await searchOptions('Хабаровский край')
})

const searchOptions = async (val) => {
  const { suggestions } = await checkAddress(val)
  const arr = []

  for (let i = 0; i < suggestions.length; i++) {
    arr.push({
      name: suggestions[i].value,
      fias_code: suggestions[i].data.fias_id,
      id: +suggestions[i].data.kladr_id
    })
  }
  address.length = 0
  address.push(...arr)
}

defineExpose({
  address,
  condition,
  formData,
  v$,
  validateField,
  serverErrors,
  searchOptions
})
</script>
